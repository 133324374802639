import React, { useEffect, useRef, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useDispatch } from 'react-redux';
import { exchangeLineToken } from '../../../actions/tokenAction';
import RenderCards from '../components/RenderCards';
import { getOSByPlatform } from '../../../utils/getOSByPlatform';
import { logEvent } from '../../../utils/dataLayers/analytics';
import { customAlert } from '../../../utils/customAlert';
import { isDevelop } from '../../../utils/getEnv';
import { useLineAuth } from '../../../hooks/useLineAuth';
import { config } from 'src/env';
import sha256 from "crypto-js/sha256";
import { hideSpinner, showSpinner } from 'src/components/common/AppProvider';

const getGaClientId = () => {
    const match = document.cookie.match(/_ga=([^;]+)/);
    return match ? match[1] : null;
};

const WelcomePage: React.FC = () => {
    const { uuid, lineIdToken } = useLineAuth();
    const dispatch = useDispatch();
    const effectRan = useRef(false);
    const lineGaClientId = getGaClientId();
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        if (lineIdToken) {
            setTimeout(() => {
                hideSpinner()
            }, 1000);
        } else {
            setTimeout(() => {
                showSpinner();
                setLoading(true)
            }, 100);
        }
    }, [lineIdToken])

    useEffect(() => {
        if (!effectRan.current && lineGaClientId && uuid) {
            const hashUUID = (uuid: string): string => {
                return sha256(uuid).toString();
            };
            
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_register',
                action: 'land_to_register_page',
                label: 'krungsri_simple_service',
                customDimensions: {
                    line_ga_client_id: lineGaClientId,
                    line_uuid: hashUUID(uuid),
                }
            })
            effectRan.current = true;
        }
    }, [lineGaClientId, uuid]);

    const alertAppVerison = () => {
        const liffId = config.LiffId;
        const hashUUID = (uuid: string): string => {
            return sha256(uuid).toString();
        };
        customAlert(
            `App Version: 0.0.78`,
            `lineIdToken: ${lineIdToken}`,
            `LINE UUID: ${uuid}`,
            `HASH LINE UUID: ${hashUUID(uuid)}`,
            `Liff ID: ${liffId}`,
            `Device OS: ${getOSByPlatform()}`
        );
    };

    const handleCopyLineId = () => {
        if (isDevelop) {
            navigator.clipboard.writeText(lineIdToken || 'CAN NOT GET lineIdToken')
            customAlert('Copy lineIdToken');
        }
    };

    const handleCopyAccessToken = () => {
        if (isDevelop) {
            const accessToken = sessionStorage.getItem('accessToken');
            navigator.clipboard.writeText(accessToken || 'CAN NOT GET accessToken')
            customAlert('Copy accessToken');
        }
    };

    const goToNextPage = async () => {
        dispatch(exchangeLineToken(lineIdToken));
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_register',
            action: 'click_button',
            label: 'register',
        });
    }

    const items = [
        {
            subtitle: 'แจ้งเตือน',
            description: 'รู้ทุกความเคลื่อนไหว เมื่อเงินเข้า-ออก สูงสุด 15 บัญชี ',
            image: 'account',
            onClickHandler: alertAppVerison,
        },
        {
            subtitle: 'เช็กยอด',
            description: 'ตรวจสอบยอดเงินคงเหลือในบัญชีได้ทุกที่ทุกเวลา',
            image: 'bookbank',
            onClickHandler: handleCopyLineId,
        },
        {
            subtitle: 'ขอ Statement',
            description: 'ขอดูรายการเดินบัญชีย้อนหลังได้ด้วยตนเองทันที',
            image: 'statement',
            onClickHandler: handleCopyAccessToken,
        },
    ];

    return (
        <>
            {loading && (
                <PageProvider>
                <Title style={{ marginBottom: 16 }}>สมัครง่าย ได้ครบทุกบริการ</Title>
                <RenderCards items={items} />
                <ButtomSpan>
                    <Button text={'ถัดไป'} onClick={goToNextPage} type={0} />
                </ButtomSpan>
            </PageProvider>
            )}
        </>
    );
};

export default WelcomePage;
