import { config } from "src/env";
import { handleApiError } from "./apiHelper";
import axios, { AxiosError } from "axios";
import { getOSByPlatform } from "src/utils/getOSByPlatform";

export const apiValidateUserStatusFromAccountId = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const accountRef = localStorage.getItem('accountRef');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/statements/pre-generate-validate?bank_acc_id=${accountRef}`, {
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-Action': 'CHECK_PRE_GENERATE',
        'X-Device-Os': deviceOS,
        'X-Purpose': 'Request Statement',
        'X-Scenario-Id': `${scenarioId}`,
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};
