import { call, put, take, takeLatest, delay } from 'redux-saga/effects';
import { ADD_PRODUCT, GET_SURVEYS_LIST, setSurveysList, SAVE_SURVEYS_LIST, GET_USER_SURVEY, DELETE_ACCOUNT } from "../actions/accountAction";
import { storeOldUserSurvey } from '../actions/settingAction';
import { setBankAccounts } from "../actions/registerAction";
import { apiAddAccount, apiGetSurveysList, apiSaveSurveysList, apiGetUserSurvey, apiDeleteAccount, apiSoftAddAccount } from "../api/api";
import { showSpinner, hideSpinner, showPopupValid, hidePopupValid, showPopupAlert, hidePopupAlert } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { safeNavigate } from '../utils/navigation';
import { pushEvent11, pushEvent12, pushEvent13, pushEvent3, pushEvent4, pushEvent6 } from '../utils/dataLayers/register_after_otp_layer';
import { logEvent } from '../utils/dataLayers/analytics';
import { errorMessages } from '../utils/errorCode';
import { SagaIterator } from 'redux-saga';
import { pushFinishSurvey } from '../utils/dataLayers/survay_layer';
import { BankAccount } from 'src/reducers/registerReducer';

function* workerAddProduct(payload: {accountNumber: string, oldBankList: BankAccount[], isFirstAccount?: boolean, defaultBankAccounts?: BankAccount[]}): SagaIterator {
    const { accountNumber, oldBankList, isFirstAccount, defaultBankAccounts } = payload;
    const flow = sessionStorage.getItem('flow');
    try {
        yield call(showSpinner);

        if (defaultBankAccounts && defaultBankAccounts.length > 0) {
            const existingAccount = defaultBankAccounts.find(
                (account: { accountNumber: string; }) => account.accountNumber === accountNumber
            );

            if (existingAccount) {
                const updatedBankList = [
                    ...oldBankList,
                    {
                        accountId: existingAccount.accountId,
                        accountNumber: existingAccount.accountNumber,
                        accountType: existingAccount.accountType,
                        isNotificationEnabled: existingAccount.isNotificationEnabled,
                        accountStatus: existingAccount.accountStatus,
                    },
                ];
                yield put(setBankAccounts(updatedBankList));
                yield call(showPopupValid, { title: 'เพิ่มบัญชีสำเร็จ' })

                if (flow === 'register') {
                    pushEvent11();
                } else {
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account_pop_up',
                        action: 'add_more_product_popup',
                        label: 'add_account_completed'
                    });
                }
                yield delay(2000)
                yield call(hidePopupValid)
                yield call(safeNavigate, 'confirm-account')
                return;
            }
        }

        let response;
        if (flow === 'confirm-account') {
            response = yield call(apiSoftAddAccount, accountNumber);
        } else {
            response = yield call(apiAddAccount, accountNumber);
        }

        if (response.success) {
            const updatedBankList = [
                ...oldBankList,
                {
                    accountNumber: response.data.accountNumber,
                    accountType: response.data.accountType,
                    isNotificationEnabled: true,
                    accountStatus: "Active"
                },
            ];
            yield put(setBankAccounts(updatedBankList));
            yield call(showPopupValid, { title: 'เพิ่มบัญชีสำเร็จ' })
            if (flow === 'register') {
                pushEvent11();
            } else {
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account_pop_up',
                    action: 'add_more_product_popup',
                    label: 'add_account_completed'
                });
            }
            if (isFirstAccount && flow === 'register') {
                pushEvent6();
            }
            yield delay(2000)
            yield call(hidePopupValid)
            yield call(safeNavigate, 'confirm-account')
        } else {
            if (response.code === 'LKS0504') {
                const { title, description } = errorMessages["LKS0504"];
                showPopupAlert({
                    title: title,
                    description: description,
                    iconType: 1,
                    ButtonText: 'ตกลง',
                    ButtonClick: () => {
                        hidePopupAlert();
                        switch (flow) {
                            case 'confirm-account':
                                logEvent({
                                    event: 'track_event',
                                    category: 'line_krungsri_simple_manage_account_error_pop_up',
                                    action: 'click_button',
                                    label: 'agree',
                                    error: 'account_not_support'
                                })
                                break;
                            default:
                                if (isFirstAccount) {
                                    pushEvent4('account_not_support');
                                } else {
                                    pushEvent13('account_not_support');
                                }
                                break;
                        }
                    },
                })
                if (flow === 'confirm-account') {
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account_error_pop_up',
                        action: 'add_more_account_error',
                        label: 'account_not_support'
                    })
                } else {
                    if (isFirstAccount) {
                        pushEvent3('account_not_support');
                    } else {
                        pushEvent12('account_not_support');
                    }
                }
            }
            else {
                yield call(handleStatusError, response.code);
            }
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherAddProduct(): SagaIterator {
    while (true) {
        const action = yield take(ADD_PRODUCT);
        yield call(workerAddProduct, action.payload);
    }
}

function* workerDeleteAccount(payload: {accountId: string}): SagaIterator {
    const { accountId } = payload;
    try {
        yield call(showSpinner);
        const response = yield call(apiDeleteAccount, accountId);
        if (!response.success) {
            yield call(handleStatusError, response.code);
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherDeleteAccount(): SagaIterator {
    while (true) {
        const action = yield take(DELETE_ACCOUNT);
        yield call(workerDeleteAccount, action.payload);
    }
}

function* workerGetSurveysList(): SagaIterator {
    try {
        yield call(showSpinner);
        const response = yield call(apiGetSurveysList, 'verifyToken');
        if (response.success) {
            const mappedChoices = response.data.choices.map((choice: string, index: number) => ({
                id: (index + 1).toString(),
                name: choice,
                type: 2,
            }));
            const surveyList = {
                uid: response.data.uid,
                data: mappedChoices
            }
            yield put(setSurveysList(surveyList))
        } else {
            yield call(handleStatusError, response.code);
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetSurveysList(): SagaIterator {
    yield takeLatest(GET_SURVEYS_LIST, workerGetSurveysList);
}

function* workerSaveSurveysList(payload: {answers: string[], id: string, mapIndexOfSelected: string[]}): SagaIterator {
    yield call(showSpinner);
    const { answers, id, mapIndexOfSelected } = payload
    try {
        const response = yield call(apiSaveSurveysList, answers, id);
        if (response.success) {
            yield call(pushFinishSurvey, answers, mapIndexOfSelected)
            yield delay(1500)
            yield call(safeNavigate, 'success')
            yield call(hideSpinner);
        } else {
            yield call(handleStatusError, response.code);
            yield call(hideSpinner);
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
        yield call(hideSpinner);
    }
}

export function* watcherSaveSurveysList(): SagaIterator {
    while (true) {
        const action = yield take(SAVE_SURVEYS_LIST);
        yield call(workerSaveSurveysList, action.payload);
    }
}

function* workerGetUserSurvey(): SagaIterator {
    try {
        yield call(showSpinner);

        const response = yield call(apiGetUserSurvey);
        if (!response.success) {
            yield call(handleStatusError, response.code);
            return;
        }

        const surveyListResponse = yield call(apiGetSurveysList, 'accessToken');
        if (!surveyListResponse.success) {
            yield call(handleStatusError, surveyListResponse.code);
            return;
        }

        const userSurveyMap = new Map(Object.entries(response.data.answers));
        const oldChoices = Array.from(userSurveyMap.entries())
            .filter(([userChoice]) => !surveyListResponse.data.choices.includes(userChoice))
            .map(([userChoice], idx) => ({
                id: (idx + 1).toString(),
                name: userChoice,
            }));

        if (oldChoices.length > 0) {
            yield put(storeOldUserSurvey(oldChoices));
        }
        const mappedChoices = surveyListResponse.data.choices.map((choice: string, index: number) => {
            const userChoice = userSurveyMap.get(choice);
            return {
                id: (index + 1).toString(),
                name: choice,
                type: userChoice ? 0 : 2
            };
        });
        const surveys = {
            uid: response.data.uid,
            data: mappedChoices
        }
        yield put(setSurveysList(surveys));
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetUserSurvey(): SagaIterator {
    yield takeLatest(GET_USER_SURVEY, workerGetUserSurvey);
}
