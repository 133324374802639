import { BankAccount } from "../reducers/registerReducer";

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const GET_SURVEYS_LIST = 'GET_SURVEYS_LIST'
export const GET_USER_SURVEY = 'GET_USER_SURVEY'
export const SET_SURVEYS_LIST = 'SET_SURVEYS_LIST'
export const SAVE_SURVEYS_LIST = 'SAVE_SURVEYS_LIST'

export const addProduct = (accountNumber: string, oldBankList: BankAccount[], isFirstAccount?: boolean, defaultBankAccounts?: BankAccount[]) => ({
  type: ADD_PRODUCT,
  payload: {
    accountNumber,
    oldBankList,
    isFirstAccount,
    defaultBankAccounts,
  },
});

export const deleteAccount = (accountId: string) => ({
  type: DELETE_ACCOUNT,
  payload: {
    accountId,
  }
})

export const setBankAccounts = (bankAccounts: BankAccount[]) => ({
  type: SET_BANK_ACCOUNTS,
  payload: bankAccounts,
});

export const getSurveysList = () => {
  return {
    type: GET_SURVEYS_LIST
  }
}

export const setSurveysList = (survey: { uid: string; data: { id: string; name: string; type: number; }[]; }) => {
  return {
    type: SET_SURVEYS_LIST,
    payload: { survey },
  }
};

export const saveSurveysList = (answers: string[], id: string, mapIndexOfSelected: string[]) => ({
  type: SAVE_SURVEYS_LIST,
  payload: { answers, id, mapIndexOfSelected },
});

export const getUserSurvey = () => {
  return {
    type: GET_USER_SURVEY,
  }
}