import { all, call } from "redux-saga/effects";
import { watcherExchangeLineToken, watcherExchangeLineTokenAfterKma, watcherExchangeLineTokenBeforeSetting, watcherExchangeLineTokenBeforeStatement } from "./tokenSaga";
import {
  watcherFetchTermsAndConditions,
  watcherAcceptTermsAndConditions,
  watcherSaveUserInfo,
  watcherCreateDeepLink,
  watcherVerifyDeepLink,
  watcherSubmitRegister,
  watcherGenerateOtp,
  watcherVerifyOtp,
  watcherGetConsentList,
  watcherSaveConsentsList,
  watcherGetBankAccount,
  watcherVerifyCustomer,
  watcherOnlineStatus,
  watcherValidateCaptcha,
  watcherSubmitBankAccounts,
} from "./registerSaga";
import {
  watcherAddProduct,
  watcherGetSurveysList,
  watcherSaveSurveysList,
  watcherGetUserSurvey,
  watcherDeleteAccount,
} from "./accoutSaga";
import {
  showPopupAlert,
  hidePopupAlert,
  hideSpinner,
} from "../components/common/AppProvider";
import { errorMessages } from "../utils/errorCode";
import {
  watcherGetAccountDetail,
  watcherGenerateStatement,
} from "./statementSaga";
import {
  watcherVerifyCustomerCommonFlow,
  watcherCheckPreSettingCondition,
  watcherSaveSurveysSetting,
  watcherUnregisterUser,
} from "./settingSaga";
import { pushEvent10, pushEvent11 } from "../utils/dataLayers/consent_layer";
import { logEvent } from "../utils/dataLayers/analytics";
import { pushOkButton, pushOtpErrorPage } from "../utils/dataLayers/otp_layer";
import { pushEvent13, pushEvent3 } from "../utils/dataLayers/register_after_otp_layer";
import { watcherFetchFeatureFlags } from "./featureFlagSaga";
import { safeNavigate } from "../utils/navigation";

const storeOfflineEvent = (event: string) => {
  const offlineEvents = JSON.parse(localStorage.getItem("offlineEvents") || "[]");
  offlineEvents.push({ event, timestamp: new Date().toISOString() });
  localStorage.setItem("offlineEvents", JSON.stringify(offlineEvents));
};

export function* sendOfflineEventsToGA() {
  const offlineEvents = JSON.parse(
    localStorage.getItem("offlineEvents") || "[]"
  );
  if (offlineEvents.length > 0) {
    for (const event of offlineEvents) {
      if (event.event === "system_error_internet") {
        yield call(pushEvent10, event.event);
      } else if (event.event === "system_error_internet_click") {
        yield call(pushEvent11, "system_error_internet");
      }
    }

    localStorage.removeItem("offlineEvents");
  }
}

export function* handleStatusError(code: string) {
  const flow = sessionStorage.getItem("flow");

  const isOnline: boolean = navigator.onLine;

  if (!isOnline) {
    storeOfflineEvent("system_error_internet");
  }

  switch (code) {
    case "LKS0205":
      if (flow === "register") {
        pushOtpErrorPage("incorrect_otp");
      } else {
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_service_otp_error_pop_up",
          action: "otp_error",
          label: "incorrect_otp",
        });
      }
      break;
    case "LKS0605":
      logEvent({
        event: "track_event",
        category: "line_krungsri_simple_service_otp_error_pop_up",
        action: "otp_error",
        label: "customer_info_not_found",
      });
      break;
    case "LKS0503":
      if (flow === "register") {
        pushEvent3("account_no_not_found");
      } else {
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_account_error_pop_up",
          action: "add_more_account_error",
          label: "account_no_not_found",
        });
      }
      break;
    case "LKS0506":
      if (flow === "register") {
        pushEvent3("account_suspended");
      } else {
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_account_error_pop_up",
          action: "add_more_account_error",
          label: "account_suspended",
        });
      }
      break;
  }

  const { title, description } = errorMessages[code] || {}

  if (title) {
    yield call(showPopupAlert, {
      logMessage: `code: ${code}`,
      title: title,
      description: description,
      iconType: 1,
      ButtonText: "ตกลง",
      ButtonClick: () => {
        hidePopupAlert();
        switch (code) {
          case "LKS0605":
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_service_otp_error_pop_up",
              action: "click_button",
              label: "agree",
              error: "customer_info_not_found",
            });
            break;
          case "LKS0402":
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_error_pop_up",
              action: "click_button",
              label: "agree",
              error: "customer_info_not_found",
            });
            break;
          case "LKS0503":
            if (flow === "register") {
              pushEvent13("account_no_not_found");
            } else {
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_account_error_pop_up",
                action: "click_button",
                label: "agree",
                error: "account_no_not_found",
              });
            }
            break;
          case "LKS0506":
            if (flow === "register") {
              pushEvent13("account_suspended");
            } else {
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_account_error_pop_up",
                action: "click_button",
                label: "agree",
                error: "account_suspended",
              });
            }
            break;
          case "LKS0205":
            if (flow === "register") {
              pushOkButton("incorrect_otp");
            } else {
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_service_otp_error_pop_up",
                action: "click_button",
                label: "agree",
                error: "incorrect_otp",
              });
            }
            break;
          default:
            break;
        }
      },
    });
  } else {
    yield call(safeNavigate, 'general-error')
    yield call(hideSpinner)
  }
}

export default function* rootSaga() {
  yield all([
    watcherExchangeLineToken(),
    watcherFetchTermsAndConditions(),
    watcherAcceptTermsAndConditions(),
    watcherSaveUserInfo(),
    watcherCreateDeepLink(),
    watcherVerifyDeepLink(),
    watcherSubmitRegister(),
    watcherAddProduct(),
    watcherGetSurveysList(),
    watcherSaveSurveysList(),
    watcherGenerateOtp(),
    watcherVerifyOtp(),
    watcherGetAccountDetail(),
    watcherGenerateStatement(),
    watcherGetUserSurvey(),
    watcherSaveSurveysSetting(),
    watcherCheckPreSettingCondition(),
    watcherGetConsentList(),
    watcherSaveConsentsList(),
    watcherUnregisterUser(),
    watcherGetBankAccount(),
    watcherDeleteAccount(),
    watcherVerifyCustomerCommonFlow(),
    watcherVerifyCustomer(),
    watcherOnlineStatus(),
    watcherValidateCaptcha(),
    watcherFetchFeatureFlags(),
    watcherExchangeLineTokenAfterKma(),
    watcherExchangeLineTokenBeforeSetting(),
    watcherExchangeLineTokenBeforeStatement(),
    watcherSubmitBankAccounts(),
  ]);
}
