export const FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

export const fetchFeatureFlags = () => ({
  type: FETCH_FEATURE_FLAGS,
});


export const setFeatureFlags = (data: {
  enableRegistrationConsentManagement: boolean;
  enableRegistrationKma: boolean;
  enableSettingConsentManagement: boolean;
  enableSettingKma: boolean;
}) => ({
  type: SET_FEATURE_FLAGS,
  payload: data,
});
