import React, { CSSProperties, useEffect, useState } from 'react';

interface CalendarModalProps {
    isOpen: boolean;
    onClose: (selectedDate: Date, formattedDate: string) => void;
    initialDate?: Date | null;
    maxDate?: Date;
    minDate?: Date;
}

const CalendarModal: React.FC<CalendarModalProps> = ({
    isOpen,
    onClose,
    initialDate,
    maxDate,
    minDate,
}) => {
    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

    const [currentDate, setCurrentDate] = useState(initialDate || new Date());
    const [fromDate, setFromDate] = useState<Date | null>(initialDate || null);

    useEffect(() => {
        if (initialDate) {
            setFromDate(initialDate);
        }
    }, [initialDate]);

    const handleSelectFromDay = (day: number) => {
        const newSelectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        if (newSelectedDate > today || newSelectedDate < oneYearAgo || (maxDate && newSelectedDate > maxDate)) {
            return;
        }
        setFromDate(newSelectedDate);
    };

    const isOlderThanOneYear = (date: Date) => {
        return date < oneYearAgo;
    };

    const isCurrentMonth = (date: Date) => {
        return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth();
    };

    const isDisabled = (currentDay: Date): boolean => {
        const isAfterMaxDate = maxDate ? currentDay > maxDate : false;
        const isBeforeMinDate = minDate ? currentDay < minDate : false;

        return (
            currentDay > today ||
            currentDay <= oneYearAgo ||
            isAfterMaxDate ||
            isBeforeMinDate
        );
    };

    const handleDone = () => {
        const selectedDate = fromDate || new Date();
        const dateAtMidnight = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0);
        const formattedDate = selectedDate.toLocaleDateString();
        onClose(dateAtMidnight, formattedDate);
    };

    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const daysInPrevMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).getDate();
    const prevMonthDays = [...Array(firstDayOfMonth)].map((_, index) => daysInPrevMonth - firstDayOfMonth + index + 1);

    const remainingCells = (7 - ((prevMonthDays.length + daysInCurrentMonth) % 7)) % 7;
    const nextMonthDays = [...Array(remainingCells)].map((_, index) => index + 1);

    const isSelectedDay = (day: number) => {
        if (!fromDate) return false;
        return (
            fromDate.getFullYear() === currentDate.getFullYear() &&
            fromDate.getMonth() === currentDate.getMonth() &&
            fromDate.getDate() === day
        );
    };

    if (!isOpen) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.calendarContainer} onClick={(e) => e.stopPropagation()}>
                <div style={styles.header}>
                    <span style={styles.monthYear}>
                        {`${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear() + 543}`}
                    </span>
                    <div style={styles.arrowButtonContainer}>
                        <button
                            style={{
                                ...styles.arrowButton,
                                ...(isOlderThanOneYear(currentDate) && styles.disabledButton),
                            }}
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))}
                            disabled={isOlderThanOneYear(currentDate)}
                        >
                            <img src={'/svg/arrowPrevious.svg'} alt="Previous month" style={styles.arrowIcon} />
                        </button>

                        <button
                            style={{
                                ...styles.arrowButton,
                                ...(isCurrentMonth(currentDate) && styles.disabledButton),
                            }}
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))}
                            disabled={isCurrentMonth(currentDate)}
                        >
                            <img src={'/svg/arrowNext.svg'} alt="Next month" style={styles.arrowIcon} />
                        </button>
                    </div>
                </div>

                <div style={styles.daysOfWeekContainer}>
                    {daysOfWeek.map((day) => (
                        <div key={day} style={styles.dayOfWeek}>
                            {day}
                        </div>
                    ))}
                </div>

                <div style={styles.daysContainer}>
                    {prevMonthDays.map((_, index) => (
                        <div
                            key={`prev-blank-${index}`}
                            style={{
                                ...styles.day,
                                visibility: 'hidden',
                            }}
                        />
                    ))}

                    {[...Array(daysInCurrentMonth)].map((_, index) => {
                        const day = index + 1;
                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

                        return (
                            <div
                                key={`current-${day}`}
                                style={{
                                    ...styles.day,
                                    ...(isSelectedDay(day) ? styles.selectedDay : styles.currentMonthDay),
                                    ...(isDisabled(currentDay) && styles.disabledDay),
                                }}
                                onClick={() => !isDisabled(currentDay) && handleSelectFromDay(day)}
                            >
                                {day}
                            </div>
                        );
                    })}

                    {nextMonthDays.map((_, index) => (
                        <div
                            key={`next-blank-${index}`}
                            style={{
                                ...styles.day,
                                visibility: 'hidden',
                            }}
                        />
                    ))}
                </div>

                <div style={styles.divider} />

                <div style={styles.footer}>

                    <button style={styles.doneButton} onClick={handleDone}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    day: {
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '25px',
        letterSpacing: '-0.45px',
        padding: 'auto',
        margin: '2px',
    },
    currentMonthDay: {
        color: 'var(--Labels-Primary, #000)',
    },
    otherMonthDay: {
        color: 'var(--Palette-text-placeholder_disable-color, #BBB)',
    },
    selectedDay: {
        backgroundColor: '#007AFF',
        color: '#fff',
    },
    dayOfWeek: {
        display: 'flex',
        width: '44px',
        height: '44px',
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
        color: 'var(--Labels-Tertiary, rgba(60, 60, 67, 0.30))',
        textAlign: 'center',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 590,
        letterSpacing: '0px',
    },
    arrowButtonContainer: {
        display: 'flex',
    },
    arrowButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
        color: '#007bff',
        opacity: 1,
        transition: 'opacity 0.3s ease',
    },
    disabledButton: {
        opacity: 0.5,
        color: 'grey',
        cursor: 'not-allowed',
    },
    arrowIcon: {
        width: '24px',
        height: '24px',
    },
    miniArrowIcon: {
        width: '11px',
        height: '14px',
        marginLeft: '4px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    calendarContainer: {
        backgroundColor: '#fff',
        padding: '14px 16px 12px 16px',
        borderRadius: '13px',
        width: '100%',
        marginRight: '7px',
        marginLeft: '7px',
        zIndex: 1001,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    monthYear: {
        fontSize: '20px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center', justifyContent: 'center',
    },
    daysOfWeekContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
    },
    daysContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
    },
    divider: {
        height: '0.5px',
        backgroundColor: '#e0e0e0',
        margin: '10px 0',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    resetButton: {
        color: 'var(--Colors-Blue, #007AFF)',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.23px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    doneButton: {
        color: 'var(--Colors-Blue, #007AFF)',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 590,
        lineHeight: '20px',
        letterSpacing: '-0.23px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    disabledDay: {
        color: 'var(--Palette-text-placeholder_disable-color, #BBB)',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
    },
};

export default CalendarModal;
