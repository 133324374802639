import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux';
import { setValidateCaptcha } from '../../../actions/registerAction';
import { config } from '../../../env';

interface Props {
  setCaptha: (isVerify: boolean) => void;
  setToken: (token: string) => void;
}

const RecaptchaModal: React.FC<Props> = ({ setCaptha, setToken }) => {
  const dispatch = useDispatch();
  const recaptchaKey = config.reCAPTCHAKey

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      dispatch(setValidateCaptcha(token));
      setTimeout(() => {
        setToken(token);
        setCaptha(false);
      }, 2000);
    } else {
      console.error("Failed to generate token");
    }
  };

  const backgroundStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1000,
    backgroundColor: '#222',
    opacity: 0.75,
  } as const;

  const captchaContainerStyle = {
    position: 'fixed',
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  } as const;

  return (
    <div>
      <div style={backgroundStyle}></div>

      <div style={captchaContainerStyle}>
        <ReCAPTCHA
          sitekey={recaptchaKey}
          onChange={handleRecaptchaChange}
          size="normal"
          type="image"
        />
      </div>
    </div>
  );
};

export default RecaptchaModal;
