import React, { useEffect } from "react";
import PageProvider from "../../../components/layout/PageProvider";
import ButtomSpan from "../../../components/layout/BottomSpan";
import Button from "../../../components/common/Button";
import { Title } from "../../../components/common/Texts";
import { useDispatch, useSelector } from "react-redux";
import { registerSelector } from "../../../reducers/registerReducer";
import { RootState } from "../../../reducers/rootReducer";
import { fetchPreVerifyCustomer } from "../../../actions/settingAction";
import {
  showPopupVerify,
  hidePopupVerify,
  hideSpinner,
} from "../../../components/common/AppProvider";
import { logEvent } from "../../../utils/dataLayers/analytics";
import {
  setAuthenticationReference,
} from "../../../actions/registerAction";
import { featureFlagSelector } from "../../../reducers/featureFlagReducer";
import { safeNavigate } from "../../../utils/navigation";

const PreSetting: React.FC = () => {
  const dispatch = useDispatch();
  const { authenticationApplicationUri, authenticationCount } = useSelector(
    (state: RootState) => registerSelector(state)
  );
  const { enableSettingKma } = useSelector((state: RootState) => featureFlagSelector(state));

  const flow = sessionStorage.getItem("flow");

  useEffect(() => {
    if (authenticationApplicationUri) {
      window.location.href = authenticationApplicationUri;
      const timeoutId = setTimeout(() => {
        if (document.visibilityState === "visible") {
          switch (flow) {
            case "confirm-account":
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_manage_account_error_pop_up",
                action: "verify_error",
                label: "not_found_kma_app",
              });
              break;
            case "manage-consent":
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_manage_consent_error_pop_up",
                action: "verify_error",
                label: "not_found_kma_app",
              });
              break;
            case "unbinding":
              logEvent({
                event: "track_event",
                category: "line_krungsri_simple_unbinding_error_pop_up",
                action: "verify_error",
                label: "not_found_kma_app",
              });
              break;
            default:
              break;
          }
          showPopupVerify({
            title: "ไม่พบ krungsri app บนอุปกรณ์นี้",
            description:
              "คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) \n เพื่อสมัครบริการ Krungsri Simple หรือไม่",
            primaryButtonText: "ยืนยันด้วย​ OTP",
            primaryButtonClick: () => {
              dispatch(setAuthenticationReference("", "", "", 0));
              sessionStorage.setItem("isHaveKMA", JSON.stringify(false));
              dispatch(fetchPreVerifyCustomer("otp"));
              hidePopupVerify();
              hideSpinner();
              switch (flow) {
                case "manage-consent":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_manage_consent_error_pop_up",
                    action: "click_button",
                    label: "verified_with_otp",
                    error: "not_found_kma_app",
                  });
                  break;
                case "confirm-account":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_manage_account_error_pop_up",
                    action: "click_button",
                    label: "verified_with_otp",
                    error: "not_found_kma_app",
                  });
                  break;
                case "unbinding":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_unbinding_error_pop_up",
                    action: "click_button",
                    label: "verified_with_otp",
                    error: "not_found_kma_app",
                  });
                  break;
                default:
                  break;
              }
            },
            secondaryButtonClick: () => {
              hidePopupVerify();
              hideSpinner();
              switch (flow) {
                case "manage-consent":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_manage_consent_error_pop_up",
                    action: "click_button",
                    label: "cancel_verified",
                    error: "not_found_kma_app",
                  });
                  break;
                case "confirm-account":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_manage_account_error_pop_up",
                    action: "click_button",
                    label: "cancel_verified",
                    error: "not_found_kma_app",
                  });
                  break;
                case "unbinding":
                  logEvent({
                    event: "track_event",
                    category: "line_krungsri_simple_unbinding_error_pop_up",
                    action: "click_button",
                    label: "cancel_verified",
                    error: "not_found_kma_app",
                  });
                  break;
                default:
                  break;
              }
            },
          });
        } else {
          safeNavigate('auth-kma-verification')
          hideSpinner()
        }
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [authenticationCount]);

  const goToNextPageWithKMA = () => {
    dispatch(fetchPreVerifyCustomer("kma"));
    switch (flow) {
      case "confirm-account":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_account",
          action: "click_button",
          label: "verified_by_kma",
        });
        break;
      case "manage-consent":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_consent",
          action: "click_button",
          label: "verified_by_kma",
        });
        break;
      case "unbinding":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_unbinding",
          action: "click_button",
          label: "verified_by_kma",
        });
        break;
    }
  };
  const goToNextPageWithOTP = () => {
    sessionStorage.setItem("requestStatementByOTP", JSON.stringify(true));
    dispatch(setAuthenticationReference("", "", "", 0));
    dispatch(fetchPreVerifyCustomer("otp"));
    switch (flow) {
      case "confirm-account":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_account",
          action: "click_button",
          label: "not_verified_by_kma",
        });
        break;
      case "manage-consent":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_consent",
          action: "click_button",
          label: "not_verified_by_kma",
        });
        break;
      case "unbinding":
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_unbinding",
          action: "click_button",
          label: "not_verified_by_kma",
        });
        break;
    }
  };

  return (
    <PageProvider>
      <div style={{ marginTop: 217, textAlign: "center" }}>
        <img
          src={"/svg/bayIcon.svg"}
          alt="bay icon"
          style={{ width: 56, height: 56, flexShrink: 0, marginBottom: 24 }}
        />
        <Title style={{ fontSize: 18, lineHeight: "30px" }}>
          กรุณายืนยันตัวตนเพื่อทำรายการ
        </Title>
      </div>

      <ButtomSpan>
        {enableSettingKma &&
          <Button
            style={{ marginBottom: 24 }}
            text={"ยืนยันด้วย krungsri app"}
            type={0}
            onClick={goToNextPageWithKMA}
          />
        }
        <Button
          text={"ไม่มี krungsri app"}
          type={2}
          onClick={goToNextPageWithOTP}
        />
      </ButtomSpan>
    </PageProvider>
  );
};

export default PreSetting;
