import { call, take } from 'redux-saga/effects';
import { safeNavigate } from '../utils/navigation';
import { EXCHANGE_LINE_TOKEN, EXCHANGE_LINE_TOKEN_AFTER_KMA, EXCHANGE_LINE_TOKEN_BEFORE_SETTING, EXCHANGE_LINE_TOKEN_BEFORE_STATEMENT } from '../actions/tokenAction';
import { apiGetVerify } from '../api/api';
import { showSpinner, hideSpinner } from '../components/common/AppProvider';
import { handleStatusError } from './rootSaga';
import { SagaIterator } from 'redux-saga';
import { workerVerifyDeepLink } from './registerSaga';
import { workerFetchFeatureFlags } from './featureFlagSaga';
import { workerCheckPreSettingCondition } from './settingSaga';
import { workerValidateUserStatusFromAccountId } from './statementSaga';

function* workerExchangeLineToken(payload: { lineToken: string }): SagaIterator {
  try {
    yield call(showSpinner);
    const { lineToken } = payload;
    const response = yield call(apiGetVerify, lineToken);
    if (response.success) {
      sessionStorage.setItem('accessToken', response.data.accessToken);
      yield call(workerFetchFeatureFlags);
      yield call(safeNavigate, 'terms-and-conditions')
    } else {
      yield call(hideSpinner);
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(hideSpinner);
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherExchangeLineToken(): SagaIterator {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN);
    yield call(workerExchangeLineToken, action.payload);
  }
}

function* workerExchangeLineTokenAfterKma(payload: { lineToken: string }): SagaIterator {
  try {
    const { lineToken } = payload;
    const response = yield call(apiGetVerify, lineToken);
    if (response.success) {
      sessionStorage.setItem('accessToken', response.data.accessToken);
      yield call(workerVerifyDeepLink)
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherExchangeLineTokenAfterKma(): SagaIterator {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN_AFTER_KMA);
    yield call(workerExchangeLineTokenAfterKma, action.payload);
  }
}

function* workerExchangeLineTokenBeforeSetting(payload: { lineToken: string }): SagaIterator {
  try {
    const { lineToken } = payload;
    const response = yield call(apiGetVerify, lineToken);
    if (response.success) {
      sessionStorage.setItem('accessToken', response.data.accessToken);
      yield call(workerFetchFeatureFlags)
      yield call(workerCheckPreSettingCondition)
      yield call(hideSpinner);
    } else {
      yield call(handleStatusError, response.code);
      yield call(hideSpinner);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
    yield call(hideSpinner);
  }
}

export function* watcherExchangeLineTokenBeforeSetting(): SagaIterator {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN_BEFORE_SETTING);
    yield call(workerExchangeLineTokenBeforeSetting, action.payload);
  }
}

function* workerExchangeLineTokenBeforeStatement(payload: { lineToken: string }): SagaIterator {
  try {
    const { lineToken } = payload;
    const response = yield call(apiGetVerify, lineToken);
    if (response.success) {
      sessionStorage.setItem('accessToken', response.data.accessToken);
      yield call(workerFetchFeatureFlags)
      yield call(workerValidateUserStatusFromAccountId)
      yield call(hideSpinner);
    } else {
      yield call(handleStatusError, response.code);
      yield call(hideSpinner);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
    yield call(hideSpinner);
  }
}

export function* watcherExchangeLineTokenBeforeStatement(): SagaIterator {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN_BEFORE_STATEMENT);
    yield call(workerExchangeLineTokenBeforeStatement, action.payload);
  }
}