import React, { useEffect, useState } from 'react';
import Colors from '../../assets/styles/Colors';
import { Description } from './Texts';
import { Fonts } from '../../assets/styles/Fonts';

type CustomInputProps = {
  title: string;
  subtitle?: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  validate?: (value: string) => string | null;
  format?: (value: string) => string;
  onBlur?: () => void;
  onFocus?: () => void;
  validateOnInit?: boolean;
};

const CustomInput: React.FC<CustomInputProps> = ({
  title,
  subtitle,
  placeholder,
  value,
  onChange,
  type,
  validate,
  format,
  onBlur,
  onFocus,
  validateOnInit = true,
}) => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (validate && validateOnInit) {
      setError(validate(value));
    }
  }, []);

  const handleBlur = () => {
    if (validate) {
      setError(validate(value));
    }
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (format) {
      newValue = format(newValue);
    }
    onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <Description>{title}</Description>
      <input
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
          }
        }}
        placeholder={placeholder}
        style={{
          boxSizing: 'border-box',
          outline: 'none',
          width: '100%',
          height: '44px',
          flexShrink: 0,
          borderRadius: '3px',
          background: Colors.WHITE,
          color: value ? Colors.MAIN_TEXT_COLOR : Colors.SECONDARY_TEXT_COLOR,
          fontFamily: value ? Fonts.Thongterm_Bold : Fonts.Thongterm_Medium,
          fontSize: '16px',
          fontStyle: 'normal',
          lineHeight: '29px',
          paddingLeft: '16px',
          border: `1px solid ${error ? Colors.RED : Colors.STROKE_NORMAL}`,
        }}
      />
      {!error && subtitle && <Description style={{ fontSize: '14px', lineHeight: '26px' }}>{subtitle}</Description>}
      {error && <Description style={{ fontSize: '14px', lineHeight: '26px', color: Colors.RED }}>{error}</Description>}
    </div>
  );
};

export default CustomInput;
