import React, { CSSProperties } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import { Description, Title } from '../../../components/common/Texts';
import { safeNavigate } from '../../../utils/navigation';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';


const ManageConsentPage: React.FC = () => {

    const goToNextPage = () => { safeNavigate("/manage-consent-form"); }

    const title: CSSProperties = {
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: 'Krungsri_Bold',
        textAlign: 'center',
        fontStyle: 'normal'
    };

    const custom_hr: CSSProperties = {
        width: '100%',
        height: '2px',
        border: 'none',
        backgroundColor: '#EBEBEB'
    };

    const subTitle: CSSProperties = {
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Krungsri_Bold',
    };

    return (
        <PageProvider>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    alt="IMAGE"
                    src="/svg/manageConsent.svg"
                    style={{
                        height: 160,
                        width: 160,
                    }}
                />
            </div>

            <div style={{ marginTop: '8px' }}>
                <Title style={title}>
                    จัดการข้อมูลส่วนบุคคล
                </Title>
            </div>

            <div style={{ margin: '24px 0 24px 0' }}>
                <div style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                    , height: '56px', alignItems: 'center', justifyItems: 'center'
                }} onClick={goToNextPage}>
                    <div style={subTitle}>
                        ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด
                    </div>

                    <div>
                        <img
                            alt="IMAGE"
                            src="/svg/chevronRight.svg"
                            style={{
                                height: 20,
                                width: 20,
                            }}
                        />
                    </div>
                </div>
                <hr style={custom_hr} />
                <div>

                </div>
            </div>

            <div style={{ marginTop: '24px', padding: '16px', backgroundColor: "#FFFBE6", borderRadius: '8px' }}>
                <Description style={{ fontFamily: Fonts.Krungsri_Light, fontSize: '14px', lineHeight: '22px', color: Colors.TEXT_BODY_PRIMARY }}>
                    คุณได้อ่านและรับทราบรายละเอียดต่างๆเกี่ยวกับการ
                    ขอความยินยอมและเข้าใจว่าสามารถใช้สิทธิถอน
                    ความยินยอมเมื่อใดก็ได้ ทั้งนี้ คุณสามารถศึกษา
                    รายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผย
                    ข้อมูลส่วนบุคคลของกลุ่มกรุงศรีและสิทธิต่างๆของคุณ
                    ได้ ซึ่งรวมถึงการขอถอนความยินยอมผ่านช่องทางสาขา
                    ช่องทางอิเล็กทรอนิกส์ และ/หรือ ช่องทางอื่นๆ ตามที่
                    ระบุไว้ในประกาศการคุ้มครองข้อมูลส่วนบุคคลของกลุ่ม
                    กรุงศรีที่
                    <a
                        href="https://www.krungsri.com/pdpa/privacy-notice-th"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: '#F76F00',
                            textDecoration: 'none'
                        }}
                    >
                        {' www.krungsri.com/pdpa/privacy-notice-th'}
                    </a>

                </Description>
            </div>
        </PageProvider>
    );
};

export default ManageConsentPage;
