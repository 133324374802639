import { logEvent } from "./analytics";

// CATEGORY
export const CATEGORY_ACCOUNT = 'line_krungsri_simple_account';
export const CATEGORY_ACCOUNT_ERROR_POPUP = 'line_krungsri_simple_account_error_pop_up';
export const CATEGORY_POPUP = 'line_krungsri_simple_account_pop_up';

// ACTION
export const ACTION_CLICK_BUTTON = 'click_button';
export const ACTION_FIELD_ERROR = 'field_error';
export const ACTION_ADD_ACC_ERROR = 'add_account_error';
export const ACTION_ADD_ACC_FIELD_ERROR = 'add_account_field_error';
export const ACTION_ADD_MORE_ACC_POPUP = 'add_more_account_popup';
export const ACTION_ADD_MORE_ACC_ERROR = 'add_more_account_error';
export const ACTION_TOGGLE_NOTIFICATION = 'turn_on';

// Event Functions (Refactored)
export const pushEvent1 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_CLICK_BUTTON,
    label: 'next'
  });
};

export const pushErrorFieldAppear = (field_error: string, error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: 'line_krungsri_simple_register',
    action: ACTION_FIELD_ERROR,
    label: field_error,
    error: error_reason
  });
};

export const pushEvent2 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_CLICK_BUTTON,
    label: 'back'
  });
};

export const pushEvent3 = (error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT_ERROR_POPUP,
    action: ACTION_ADD_ACC_ERROR,
    label: error_reason
  });
};

export const pushEvent4 = (error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT_ERROR_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'agree',
    error: error_reason
  });
};

export const pushEvent6 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_TOGGLE_NOTIFICATION,
    label: 'notification'
  });
};

export const pushEvent7 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_CLICK_BUTTON,
    label: 'add_more_account'
  });
};


export const pushEvent9 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_POPUP,
    action: 'register_pop_up',
    label: 'register_completed',
  });
};

export const pushEvent10 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_CLICK_BUTTON,
    label: 'add_account'
  });
};

export const pushErrorFieldAppearReg = (field_error: string, error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_ADD_ACC_FIELD_ERROR,
    label: field_error,
    error: error_reason
  });
};

export const pushEvent11 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_POPUP,
    action: ACTION_ADD_MORE_ACC_POPUP,
    label: 'add_account_completed'
  });
};

export const pushEvent12 = (error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT_ERROR_POPUP,
    action: ACTION_ADD_MORE_ACC_ERROR,
    label: error_reason
  });
};

export const pushEvent13 = (error_reason: string) => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT_ERROR_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'agree',
    error: error_reason
  });
};

export const pushEvent14 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_ACCOUNT,
    action: ACTION_CLICK_BUTTON,
    label: 'delete_account'
  });
};

export const pushEvent15 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'confirm_delete_account'
  });
};

export const pushEvent16 = () => {
  logEvent({
    event: 'track_event',
    category: CATEGORY_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'cancel_delete_account'
  });
};
