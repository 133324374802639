import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';
import BottomSpan from '../../../components/layout/BottomSpan';
import Button from '../../../components/common/Button';
import liff from '@line/liff';

const GeneralErrorPage: React.FC = () => {

    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '19vh',
            }}>
                <img src={'/svg/suspend.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '40px',
                }} />
                <div style={{ marginBottom: '8px', color: Colors.MAIN_TEXT_COLOR, textAlign: 'center', fontSize: '24px', fontFamily: Fonts.Krungsri_Bold, lineHeight: '36px' }}>ไม่สามารถทำรายการได้ในขณะนี้</div>
                <div style={{ whiteSpace: 'pre-line', color: Colors.PRIMARY_TEXT_COLOR, textAlign: 'center', fontSize: '16px', fontFamily: Fonts.Thongterm_Medium, lineHeight: '29px' }}>{`ขออภัย ระบบขัดข้องชั่วคราว\nกรุณาลองอีกครั้งในภายหลัง`}</div>
            </div>
            <BottomSpan>
                <Button text={'กลับสู่ LINE'} onClick={() => liff.closeWindow()} type={0} />
            </BottomSpan>
        </PageProvider>
    );
};

export default GeneralErrorPage;
