import React, { useEffect } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import Dot from '../../../components/common/Dot';
import { useDispatch } from 'react-redux';
import { verifyDeepLink } from '../../../actions/registerAction';
import { useLocation } from 'react-router-dom';
import { exchangeLineTokenAfterKma } from '../../../actions/tokenAction';
import { useLineAuth } from '../../../hooks/useLineAuth';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';

const KmaWaitingPage: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const { lineIdToken } = useLineAuth();

    useEffect(() => {
        const fetchAndExchangeToken = async () => {
            const wantToGoTo = localStorage.getItem('wantToGoTo')
            const decodedUri = decodeURIComponent(location.search);
            const params = new URLSearchParams(decodedUri);
            const authenticationReference = params.get('authenticationReference');
            if (authenticationReference) {
                sessionStorage.setItem('authRef', authenticationReference);
                if (lineIdToken) {
                    const regRef = localStorage.getItem('registerRef');
                    if (regRef) {
                        sessionStorage.setItem('flow', 'register');
                        dispatch(exchangeLineTokenAfterKma(lineIdToken));
                    } else {
                        sessionStorage.setItem('flow', `${wantToGoTo}`);
                        dispatch(exchangeLineTokenAfterKma(lineIdToken));
                    }
                }
            } else {
                dispatch(verifyDeepLink())
            }
        };
        fetchAndExchangeToken();
    }, [lineIdToken, location.search]);

    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '30vh',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '40px',
                }}>
                    <Dot animationDelay="0s" />
                    <Dot animationDelay="0.2s" />
                    <Dot animationDelay="0.4s" />
                </div>
                <div style={{ marginBottom: '8px', color: Colors.MAIN_TEXT_COLOR, textAlign: 'center', fontSize: '24px', fontFamily: Fonts.Krungsri_Bold, lineHeight: '36px' }}>กำลังรอการยืนยันตัวตน</div>
                <div style={{ color: Colors.PRIMARY_TEXT_COLOR, textAlign: 'center', fontSize: '16px', fontFamily: Fonts.Thongterm_Medium, lineHeight: '29px' }}>กรุณาไปยัง krungsri app เพื่อทำรายการ</div>
            </div>
        </PageProvider>
    );
};

export default KmaWaitingPage;
