import React, { useEffect } from "react";
import { getOSByPlatform } from "../../../utils/getOSByPlatform";
import { appUrl, storeUrlAndroid, storeUrlIos } from "../../../constants/links";
import { showSpinner } from "../../../components/common/AppProvider";

const OpenKmaApp: React.FC = () => {
  useEffect(() => {
    showSpinner();
    const os = getOSByPlatform();
    if (os !== "IOS" && os !== "ANDROID") {
      return;
    }
    const storeUrl = os === "IOS" ? storeUrlIos : storeUrlAndroid;
    window.location.href = appUrl;

    const timeout = setTimeout(() => {
      window.location.href = storeUrl;
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return <></>;
};

export default OpenKmaApp;
