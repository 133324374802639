import axios, { AxiosError } from "axios";
import { handleApiError } from "./apiHelper";
import { config } from "src/env";

export const apiGetFeatureFlags = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/feature-flags`, {
      maxBodyLength: Infinity,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};
