import React, { CSSProperties, useEffect, useState } from "react";
import PageProvider from "../../../components/layout/PageProvider";
import ButtomSpan from "../../../components/layout/BottomSpan";
import { Description, Title } from "../../../components/common/Texts";
import ConsentForm from "../../../components/common/ConsentForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsentList,
  saveConsentList,
} from "../../../actions/registerAction";
import { RootState } from "../../../store/store";
import {
  registerSelector,
} from "../../../reducers/registerReducer";
import Button from "../../../components/common/Button";
import { safeNavigate } from "../../../utils/navigation";
import { logEvent } from "../../../utils/dataLayers/analytics";
import Colors from "../../../assets/styles/Colors";
import { Fonts } from "../../../assets/styles/Fonts";

const ManageConsentForm: React.FC = () => {
  const dispatch = useDispatch();
  let hasWithdraw = true;

  useEffect(() => {
    dispatch(getConsentList(false));
  }, []);
  const { consents } = useSelector((state: RootState) =>
    registerSelector(state)
  );
  const [consentList, setConsentList] = useState(consents);
  const [clickable, setClickable] = useState(1);

  const handleConsentChange = (index: number, action: string) => {
    const updatedConsentList = consentList.map((item, i) =>
      i === index ? { ...item, action } : item
    );
    setClickable(0);
    setConsentList(updatedConsentList);
  };

  const handleSubmit = () => {
    const transformedConsents = consentList.map((consent) => {
      if (consent.action === "N" || consent.action === "Y") {
        hasWithdraw = false;
      }
      return {
        id: Number(consent.id),
        action: consent.action,
      };
    });

    dispatch(saveConsentList(transformedConsents));

    if (navigator.onLine) {
      goToNextPage();
    }

    const customDimensions: Record<string, string | null> = {};

    consentList.forEach((consent) => {
      const consentId = Number(consent.id);
      const consentVariable =
        consentId % 2 === 0
          ? "krungsri_group_consent"
          : "krungsri_alli_consent";

      let consentValue: string;

      if (consent.action === "N") {
        consentValue = "ignored";
      } else if (consent.action === "Y") {
        consentValue = "granted";
      } else {
        consentValue = "denied";
      }

      customDimensions[`${consentVariable}`] = consentValue;
    });

    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_manage_consent",
      action: "click_button",
      label: "confirm_manage_consent",
      customDimensions: customDimensions,
    });
  };

  useEffect(() => {
    setConsentList(consents);
  }, [consents]);

  const gotoPreviousPage = () => {
    safeNavigate("/setting");
    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_manage_consent_error_pop_up",
      action: "click_button",
      label: "cancel_manage_consent",
    });
  };

  const gotoManageConsentPage = () => {
    safeNavigate("/manage-consent");
  };

  const goToNextPage = () => {
    if (hasWithdraw) {
      safeNavigate("/success-consent/true");
    } else {
      safeNavigate("/success-consent/false");
    }
  };

  const title: CSSProperties = {
    marginBottom: '8px', fontSize: '20px', lineHeight: '30px', color: Colors.TEXT_BODY_PRIMARY, fontFamily: Fonts.Krungsri_Bold
  };

  const subTitle: CSSProperties = {
    fontSize: '14px', lineHeight: '24px', color: Colors.TEXT_BODY_PRIMARY, fontFamily: Fonts.Krungsri_Bold
  };

  return (
    <PageProvider>
      <img
        onClick={gotoManageConsentPage}
        alt="BACK BUTTON"
        src="/svg/back.svg"
        style={{
          height: 24,
          width: 24,
          cursor: "pointer",
          marginBottom: "12px",
        }}
      />

      <div style={{ marginBottom: "24px" }}>
        <Title style={title}>การให้ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด</Title>
        <Description style={subTitle}>
          การให้ความยินยอมนี้
          ไม่มีผลกระทบต่อการพิจารณาอนุมัติคำขอสมัครใช้ผลิตภัณฑ์และบริการของคุณ
        </Description>
      </div>

      <div>
        {consentList.map((item, index) => {
          if (item.action === "I") return null;

          const actionText = (action: string) => {
            switch (action) {
              case "Y":
                return "ยินยอม";
              case "P":
              case "W":
                return "ไม่ยินยอม";
              default:
                return "";
            }
          };

          return (
            <div key={index}>
              <ConsentForm
                id={item.id}
                onChange={handleConsentChange}
                index={index}
                type={1}
                action={actionText(item.action)}
                remark={item.remark}
                question={item.name}
                information={{
                  action: item.action,
                  entity: item.entity,
                  channel: item.channel,
                  updatedAt: item.updatedAt,
                  signedVersion: item.signedVersion,
                  consentReference: item.consentReference,
                }}
              />
            </div>
          );
        })}
      </div>

      <ButtomSpan>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "10px",
            marginTop: "12px",
            marginBottom: "16px",
          }}
        >
          <Button
            text={"ยกเลิก"}
            onClick={gotoPreviousPage}
            type={2}
            style={{ width: "167px" }}
          />
          <Button
            text={"ยืนยัน"}
            onClick={handleSubmit}
            style={{ width: "167px" }}
            type={clickable === 0 || clickable === 1 ? clickable : 0}
          />
        </div>
      </ButtomSpan>
    </PageProvider>
  );
};

export default ManageConsentForm;
