import React, { useState, useEffect, createContext, useContext } from 'react';
import EventEmitter from 'eventemitter3';
import Spinner from './Spinner';
import PopupAlert from './popup/PopUpAlert';
import PopupValid from './popup/PopupValid';
import PopupVerify from './popup/PopupVerify';

const dialogEvent = new EventEmitter();

interface PopupAlertConfig {
    logMessage?: string;
    title: string;
    description: string;
    iconType?: number;
    ButtonText?: string;
    ButtonClick?: () => void;
}

interface PopupValidConfig {
    title: string;
}

interface PopupVerifyConfig {
    title: string;
    description: string;
    primaryButtonText: string;
    primaryButtonClick: () => void;
    secondaryButtonClick: () => void;
}

interface AppContextType {
    showSpinner: () => void;
    hideSpinner: () => void;
    showPopupAlert: (config: PopupAlertConfig) => void;
    hidePopupAlert: () => void;
    showPopupValid: (config: PopupValidConfig) => void;
    hidePopupValid: () => void;
    showPopupVerify: (config: PopupVerifyConfig) => void;
    hidePopupVerify: () => void;
}

const defaultContextValue: AppContextType = {
    showSpinner: () => { },
    hideSpinner: () => { },
    showPopupAlert: () => { },
    hidePopupAlert: () => { },
    showPopupValid: () => { },
    hidePopupValid: () => { },
    showPopupVerify: () => { },
    hidePopupVerify: () => { },
};

const AppContext = createContext<AppContextType>(defaultContextValue);

export const showSpinner = () => {
    dialogEvent.emit('show_spinner');
};

export const hideSpinner = () => {
    dialogEvent.emit('hide_spinner');
};

export const showPopupAlert = (config: PopupAlertConfig) => {
    dialogEvent.emit('show_popup_alert', config);
};

export const hidePopupAlert = () => {
    dialogEvent.emit('hide_popup_alert');
};

export const showPopupValid = (config: PopupValidConfig) => {
    dialogEvent.emit('show_popup_alert_valid', config);
};

export const hidePopupValid = () => {
    dialogEvent.emit('hide_popup_alert_valid');
};

export const showPopupVerify = (config: PopupVerifyConfig) => {
    dialogEvent.emit('show_popup_alert_verify', config);
};

export const hidePopupVerify = () => {
    dialogEvent.emit('hide_popup_alert_verify');
};

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [popupAlert, setPopupAlert] = useState<PopupAlertConfig & { isVisible: boolean }>({
        isVisible: false,
        title: '',
        description: '',
    });
    const [popupValid, setPopupValid] = useState<PopupValidConfig & { isVisible: boolean }>({
        isVisible: false,
        title: '',
    });
    const [popupVerify, setPopupVerify] = useState<PopupVerifyConfig & { isVisible: boolean }>({
        isVisible: false,
        title: '',
        description: '',
        primaryButtonText: '',
        primaryButtonClick: () => { },
        secondaryButtonClick: () => { },
    });

    useEffect(() => {
        const showSpinnerHandler = () => setSpinnerVisible(true);
        const hideSpinnerHandler = () => setSpinnerVisible(false);

        const showPopupAlertHandler = (config: PopupAlertConfig) => setPopupAlert({ ...config, isVisible: true });
        const hidePopupAlertHandler = () => setPopupAlert((prev) => ({ ...prev, isVisible: false }));

        const showPopupValidHandler = (config: PopupValidConfig) => setPopupValid({ ...config, isVisible: true });
        const hidePopupValidHandler = () => setPopupValid((prev) => ({ ...prev, isVisible: false }));

        const showPopupVerifyHandler = (config: PopupVerifyConfig) => setPopupVerify({ ...config, isVisible: true });
        const hidePopupVerifyHandler = () => setPopupVerify((prev) => ({ ...prev, isVisible: false }));

        dialogEvent.on('show_spinner', showSpinnerHandler);
        dialogEvent.on('hide_spinner', hideSpinnerHandler);
        dialogEvent.on('show_popup_alert', showPopupAlertHandler);
        dialogEvent.on('hide_popup_alert', hidePopupAlertHandler);
        dialogEvent.on('show_popup_alert_valid', showPopupValidHandler);
        dialogEvent.on('hide_popup_alert_valid', hidePopupValidHandler);
        dialogEvent.on('show_popup_alert_verify', showPopupVerifyHandler);
        dialogEvent.on('hide_popup_alert_verify', hidePopupVerifyHandler);

        return () => {
            dialogEvent.off('show_spinner', showSpinnerHandler);
            dialogEvent.off('hide_spinner', hideSpinnerHandler);
            dialogEvent.off('show_popup_alert', showPopupAlertHandler);
            dialogEvent.off('hide_popup_alert', hidePopupAlertHandler);
            dialogEvent.off('show_popup_alert_valid', showPopupValidHandler);
            dialogEvent.off('hide_popup_alert_valid', hidePopupValidHandler);
            dialogEvent.off('show_popup_alert_verify', showPopupVerifyHandler);
            dialogEvent.off('hide_popup_alert_verify', hidePopupVerifyHandler);
        };
    }, []);

    return (
        <AppContext.Provider
            value={{
                showSpinner,
                hideSpinner,
                showPopupAlert,
                hidePopupAlert,
                showPopupValid,
                hidePopupValid,
                showPopupVerify,
                hidePopupVerify,
            }}
        >
            {children}
            {spinnerVisible && <Spinner isVisible={spinnerVisible} />}
            {popupAlert.isVisible && (
                <PopupAlert
                    logMessage={popupAlert.logMessage}
                    isVisible={popupAlert.isVisible}
                    title={popupAlert.title}
                    description={popupAlert.description}
                    iconType={popupAlert.iconType ?? 1}
                    ButtonClick={popupAlert.ButtonClick ?? (() => hidePopupAlert())}
                />
            )}
            {popupValid.isVisible && (
                <PopupValid isVisible={popupValid.isVisible} title={popupValid.title} />
            )}
            {popupVerify.isVisible && (
                <PopupVerify
                    isVisible={popupVerify.isVisible}
                    title={popupVerify.title}
                    description={popupVerify.description}
                    primaryButtonText={popupVerify.primaryButtonText}
                    primaryButtonClick={popupVerify.primaryButtonClick}
                    secondaryButtonClick={popupVerify.secondaryButtonClick}
                />
            )}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
