import { config } from 'src/env';
import { getOSByPlatform } from '../utils/getOSByPlatform';
import { x_purpose } from '../utils/purpose';
import { handleApiError } from './apiHelper';
import axios, { AxiosError } from 'axios';

export const apiGetVerify = async (lineIdToken: string) => {
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verify-token`, {
      "lineIdToken": lineIdToken,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGetTermsAndConditions = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/term-and-conditions`, {
      maxBodyLength: Infinity,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiAcceptTermsAndConditions = async (id: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/term-and-conditions/accept`,
      {
        acceptedId: id
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'ACCEPT_TERM_AND_CONDITION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiSaveUserInfo = async (identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, reCaptchaToken: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOsHeader = getOSByPlatform()
  const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')

  let endpoint = `${config.Domain}/services/lks/inter/api/v1/registers/otp/pre-register`;
  if (isHaveKMA) {
    endpoint = `${config.Domain}/services/lks/inter/api/v1/registers/kma/pre-register`;
  }
  try {
    const response = await axios.post(endpoint,
      {
        "identificationNumber": identificationNumber,
        "mobileNumber": mobileNumber,
        "referral": referral,
        "recaptchaToken": reCaptchaToken,
        "deviceOS": deviceOS
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': isHaveKMA ? 'PRE_REGISTER_KMA' : 'PRE_REGISTER_OTP',
          'X-Device-Os': deviceOsHeader,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGenerateOtp = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/registers/otp/request`,
      {
        "registerRef": registerRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiVerifyOtp = async (otpRef: string, otpCode: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/registers/otp/verify`,
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};


export const apiCreateDeepLink = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/registers/kma/deep-link/request`,
      {
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiVerifyDeepLink = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  const authRef = sessionStorage.getItem('authRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/registers/kma/deep-link/verify`,
      {
        "registerRef": registerRef,
        "authRef": authRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiSubmitRegister = async (accounts: { accountType: string, accountNumber: string; isNotificationEnabled: boolean }[]) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/registers/submit`,
      {
        "registerRef": registerRef,
        "registerAccounts": accounts
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_REGISTRATION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiAddAccount = async (accountNumber: string) => {
  const registerRef = localStorage.getItem('registerRef');
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/bank-accounts/soft-add`,
      {
        "registerRef": registerRef,
        "accountNumber": accountNumber
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_ADD_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiDeleteAccount = async (accountId: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/bank-accounts/soft-delete`,
      {
        "accountId": accountId
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_DELETE_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Account',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGetSurveysList = async (tokenType: string) => {
  const token = sessionStorage.getItem(`${tokenType}`);
  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/active/surveys`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGetUserSurvey = async () => {
  const accessToken = sessionStorage.getItem('accessToken');

  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/active/survey-submissions`,
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiSaveSurveysList = async (answers: string[], id: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.put(`${config.Domain}/services/lks/inter/api/v1/survey-submissions/${id}`,
      {
        "answers": answers
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_MARGETING_SURVEY',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Add Marketing Survey',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiSaveSurveysListSetting = async (answers: string[], id: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.put(`${config.Domain}/services/lks/inter/api/v1/survey-submissions/${id}`,
      {
        "answers": answers
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'SUBMIT_MARGETING_SURVEY',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Marketing Survey',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiGetAccountDetail = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const accountRef = localStorage.getItem('accountRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/bank-accounts/account-details/statement`,
      {
        "accountRef": accountRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGenerateStatement = async (
  language: string,
  fromBookingDateTime: string,
  toBookingDateTime: string,
  email: string,
) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const accountRef = localStorage.getItem('accountRef');
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/statements`,
      {
        "accountRef": accountRef,
        "Language": language,
        "FromBookingDateTime": fromBookingDateTime,
        "ToBookingDateTime": toBookingDateTime,
        "Email": email,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'REQUEST_STATEMENT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Request Statement',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGenerateOtpCommon = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/otp/request`,
      {},
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiVerifyOtpCommon = async (otpRef: string, otpCode: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/otp/verify`,
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiGetConsentsList = async (excludeAccepted: boolean) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';

  const url = excludeAccepted
    ? `${config.Domain}/services/lks/inter/api/v1/consents?excludeAccepted=true`
    : `${config.Domain}/services/lks/inter/api/v1/consents`;
  try {
    const response = await axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${verifyToken}`,
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );

    return { success: true, data: response };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};


export const apiSaveConsentsList = async (consents: { id: number, action: string }[]) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.put(`${config.Domain}/services/lks/inter/api/v1/consents`,
      {
        "consents": consents
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'ACCEPT_CONSENT',
          'X-Device-Os': deviceOS,
          'X-Purpose': flow === 'register' ? 'Accept Consent' : 'Manage Consent',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiCheckPreSettingCondition = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/settings/check-pre-setting`,
      {

      }
      , {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_PRE_SETTING',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiUnregisterUser = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/un-registers`,
      {},
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'UNREGISTRATION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Unregister',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiGetBankAccount = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/bank-accounts`,
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiSoftAddAccount = async (accountNumber: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/bank-accounts/soft-add`,
      {
        "accountNumber": accountNumber
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_ADD_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiSubmitBankAccount = async (accounts: { accountId: string, accountNumber: string; isNotificationEnabled: boolean }[]) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/bank-accounts/submit`,
      {
        "accounts": accounts,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Account',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiKmaRequest = async (purpose: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const accountRef = localStorage.getItem('accountRef') || "";
  const flow = sessionStorage.getItem('flow') || '';
  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/kma/request`,
      {
        "redirectPath": '/auth-kma-verification',
        "purpose": purpose,
        "accountReference": accountRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiKmaVerify = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const authRef = sessionStorage.getItem('authRef');
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/kma/verify`,
      {
        "authRef": authRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const apiCheckPreVerifyCustomer = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/check-pre-verify-customer`,
      {
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_PRE_VERIFY_CUSTOMER',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiVerifyCustomer = async (mobileNumber: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/verify-customer`,
      {
        "mobileNumber": mobileNumber,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'VERIFY_CUSTOMER',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};


export const apiGetValidateCaptcha = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';

  try {
    const response = await axios.get(`${config.Domain}/services/lks/inter/api/v1/registers/validate-captcha`,
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
}

export const apiCheckOtpAvailable = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await axios.post(`${config.Domain}/services/lks/inter/api/v1/verification/otp/check-generate-available`,
      {
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_GENERATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};
