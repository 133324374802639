export const afterThisTime = (minutes: number): string => {
    const futureTime = minutes < 0 ? new Date() : new Date(Date.now() + minutes * 60 * 1000);
    return futureTime.toISOString();
};

export const isTimeExpired = (expirationTime: string | null | undefined): boolean => {
    if (!expirationTime) return true;
    const now = new Date();
    const expiryDate = new Date(expirationTime);
    return now > expiryDate;
};

export const calculateTimeToUnlock = (unlockTime: string): string => {
    const now = new Date();
    const unlockDate = new Date(unlockTime);
    const difference = unlockDate.getTime() - now.getTime();
    const secondsRemaining = Math.floor(difference / 1000);
    const formattedTime = secondsRemaining.toString();
    return formattedTime;
};