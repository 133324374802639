import React, { useEffect, useState } from "react";
import PageProvider from "../../../components/layout/PageProvider";
import ButtomSpan from "../../../components/layout/BottomSpan";
import { Description, Title } from "../../../components/common/Texts";
import ConsentForm from "../../../components/common/ConsentForm";
import { useDispatch, useSelector } from "react-redux";
import { saveConsentList } from "../../../actions/registerAction";
import { RootState } from "../../../store/store";
import { registerSelector } from "../../../reducers/registerReducer";
import Button from "../../../components/common/Button";
import { safeNavigate } from "../../../utils/navigation";
import Colors from "../../../assets/styles/Colors";
import { Fonts } from "../../../assets/styles/Fonts";
import { logEvent } from "../../../utils/dataLayers/analytics";

const BindingConsentForm: React.FC = () => {
  const dispatch = useDispatch();
  const { consents } = useSelector((state: RootState) =>
    registerSelector(state)
  );
  const [consentList, setConsentList] = useState(consents);

  const handleConsentChange = (index: number, action: string) => {
    const updatedConsentList = consentList.map((item, i) =>
      i === index ? { ...item, action } : item
    );
    setConsentList(updatedConsentList);
  };

  const handleSubmit = () => {
    const transformedConsents = consentList.map((consent) => ({
      id: Number(consent.id),
      action: consent.action,
    }));
    dispatch(saveConsentList(transformedConsents));

    const customDimensions: Record<string, string | null> = {};

    consentList.forEach((consent) => {
      const consentId = Number(consent.id);
      const consentVariable =
        consentId % 2 === 0
          ? "krungsri_group_consent"
          : "krungsri_alli_consent";

      let consentValue: string;

      if (consent.action === "N") {
        consentValue = "ignored";
      } else if (consent.action === "Y") {
        consentValue = "granted";
      } else {
        consentValue = "denied";
      }

      customDimensions[`${consentVariable}`] = consentValue;
    });

    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_account",
      action: "click_button",
      label: "confirm_consent",
      customDimensions: customDimensions,
    });
  };

  useEffect(() => {
    setConsentList(consents);
  }, [consents]);
  const gotoPreviousPage = () => {
    handleSubmit();
    safeNavigate("/binding-consent");
  };

  const goToNextPage = () => {
    handleSubmit();
    safeNavigate("/survey");
  };

  return (
    <PageProvider>
      <img
        onClick={gotoPreviousPage}
        src="/svg/back.svg"
        style={{
          height: 24,
          width: 24,
          cursor: "pointer",
          marginBottom: "24px",
        }}
      />
      <div style={{ marginBottom: "24px" }}>
        <Title
          style={{
            marginBottom: "8px",
            fontSize: "20px",
            lineHeight: "30px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          การให้ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด
        </Title>
        <Title
          style={{
            fontSize: "14px",
            lineHeight: "24px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          การให้ความยินยอมนี้ ไม่มีผลกระทบต่อการพิจารณา
          อนุมัติคำขอสมัครใช้ผลิตภัณฑ์และบริการของคุณ
        </Title>
      </div>

      <div>
        {consentList.map((item, index) => (
          <div key={index}>
            <ConsentForm
              id={item.id}
              onChange={handleConsentChange}
              index={index}
              remark={item.remark}
              question={item.name}
            />
          </div>
        ))}
      </div>

      <div style={{ marginTop: '24px', padding: '16px', backgroundColor: "#FFFBE6", borderRadius: '8px' }}>
        <Description
          style={{
            fontFamily: Fonts.Krungsri_Light,
            fontSize: "14px",
            lineHeight: "22px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          คุณได้อ่านและรับทราบรายละเอียดต่างๆเกี่ยวกับการ
          ขอความยินยอมและเข้าใจว่าสามารถใช้สิทธิถอน ความยินยอมเมื่อใดก็ได้
          ทั้งนี้ คุณสามารถศึกษา รายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้
          หรือเปิดเผย ข้อมูลส่วนบุคคลของกลุ่มกรุงศรีและสิทธิต่างๆของคุณ ได้
          ซึ่งรวมถึงการขอถอนความยินยอมผ่านช่องทางสาขา ช่องทางอิเล็กทรอนิกส์
          และ/หรือ ช่องทางอื่นๆ ตามที่
          ระบุไว้ในประกาศการคุ้มครองข้อมูลส่วนบุคคลของกลุ่ม กรุงศรีที่
          <a
            href="https://www.krungsri.com/pdpa/privacy-notice-th"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#F76F00",
              textDecoration: "none",
            }}
          >
            {" www.krungsri.com/pdpa/privacy-notice-th"}
          </a>
        </Description>
      </div>

      <ButtomSpan>
        <Button
          text={"ยืนยัน"}
          onClick={goToNextPage}
          type={0}
          style={{ margin: "24px 0 16px 0" }}
        />
      </ButtomSpan>
    </PageProvider>
  );
};

export default BindingConsentForm;
