import React, { ChangeEvent, useEffect, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useDispatch, useSelector } from 'react-redux';
import { resetSensitiveData, saveSensitiveData, verifyCustomer } from '../../../actions/registerAction';
import CustomInput from '../../../components/common/CustomInput';
import { formatMobileNumber } from '../../../utils/formatInput';
import { safeNavigate } from '../../../utils/navigation';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { logEvent } from '../../../utils/dataLayers/analytics';
import { isIOS } from 'src/utils/getOSByPlatform';

const ReceiveOTPPage: React.FC = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        checkIsCanGoNext()
    }, [])

    const [isFirstTimeVerifyOtp, setIsFirstTimeVerifyOtp] = useState(
        () => JSON.parse(sessionStorage.getItem('isFirstTimeVerifyOtp') || 'true')
    );

    const { mobileNumber, otpExpirationTime } = useSelector((state: RootState) => registerSelector(state));

    const [phoneNumber, setPhoneNumber] = useState<string>(formatMobileNumber(mobileNumber));
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

    const adjustHeight = () => {
        const height = window.innerHeight;
        if (height < 600) {
            setIsKeyboard(true)
        } else {
            setIsKeyboard(false)
        }
    };

    useEffect(() => {
        setPhoneNumber(formatMobileNumber(mobileNumber));
      }, [mobileNumber]);

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const goToNextPage = () => {
        const phoneNumberReplaceDash = phoneNumber.replace(/-/g, '')
        dispatch(saveSensitiveData('', phoneNumberReplaceDash, ''));
        if (phoneNumberReplaceDash !== mobileNumber) {
            dispatch(verifyCustomer(phoneNumberReplaceDash, ''))
        } else {
            dispatch(verifyCustomer(phoneNumberReplaceDash, otpExpirationTime))
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_service_otp',
                action: 'click_button',
                label: 'next'
            })
        }
    };

    const goToPreviousPage = () => {
        setPhoneNumber('')
        dispatch(resetSensitiveData())
        sessionStorage.setItem('isFirstTimeVerifyOtp', JSON.stringify(true));
        safeNavigate('/request-setting');
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_service_otp',
            action: 'click_button',
            label: 'back'
        })
    };

    const handleMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value)
    };

    const validateMobileNumber = (value: string) => {
        if (isFirstTimeVerifyOtp) {
            return null;
        }
        if (!value) return 'กรุณากรอกเบอร์มือถือ';
        if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
        if (value.charAt(0) !== '0') return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
        if (value.charAt(1) === '0') return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
        return null;
    };

    const checkIsCanGoNext = () => {
        const mobileNumberError = validateMobileNumber(phoneNumber);
        setIsPhoneValid(!mobileNumberError && !isFirstTimeVerifyOtp)
        if (mobileNumberError) {
            switch (mobileNumberError) {
                case 'กรุณากรอกเบอร์มือถือ':
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp',
                        action: 'field_error',
                        label: 'phone_number',
                        error: 'not_filled'
                    })
                    break;
                case 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง':
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp',
                        action: 'field_error',
                        label: 'phone_number',
                        error: 'not_correct'
                    })
                    break;
                default:
                    break;
            }
        }
    };

    const onFocusMobileNo = () => {
        setIsFirstTimeVerifyOtp(false);
        sessionStorage.setItem('isFirstTimeVerifyOtp', JSON.stringify(false));
    }

    return (
        <PageProvider>
            <Title style={{ marginBottom: 8 }}>กรอกข้อมูลเพื่อยืนยันตัวตน</Title>
            <CustomInput
                title='เบอร์มือถือ'
                placeholder='ตัวอย่าง: 0881234567'
                subtitle='เบอร์ที่เคยให้กับธนาคารกรุงศรี'
                value={phoneNumber}
                onChange={handleMobileNo}
                type='tel'
                validate={validateMobileNumber}
                format={formatMobileNumber}
                onBlur={checkIsCanGoNext}
                onFocus={onFocusMobileNo}
            />
            <ButtomSpan>
                {(isIOS || !isKeyboard) && (
                    <div>
                        <Button style={{ marginBottom: 24 }} text={'ถัดไป'} type={isPhoneValid ? 0 : 1} onClick={goToNextPage} />
                        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
                    </div>
                )}
            </ButtomSpan>
        </PageProvider>
    );
};

export default ReceiveOTPPage;
