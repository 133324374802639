import React, { useEffect, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title, Description } from '../../../components/common/Texts';
import CheckButton from '../../../components/common/CheckButton';
import { useDispatch, useSelector } from 'react-redux';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { getSurveysList, saveSurveysList } from '../../../actions/accountAction';

const SurveyPage: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSurveysList());
    }, [dispatch]);
    const [clickable, setClickable] = useState(0);
    const { surveys } = useSelector((state: RootState) => registerSelector(state));

    const [surveyCard, setSurveyCard] = useState(surveys);
    useEffect(() => {
        setSurveyCard(surveys);
    }, [surveys]);

    const handleCheckBoxChange = (id: string) => {
        setSurveyCard((prevSurveyCard) => ({
            ...prevSurveyCard,
            data: prevSurveyCard.data.map((item) => {
                if (item.id === id) {
                    if (item.type === 0) {
                        setClickable((clickable) => --clickable);
                        return { ...item, type: 2 };
                    } else if (item.type === 2) {
                        setClickable((clickable) => ++clickable);
                        return { ...item, type: 0 };
                    }
                }
                return item;
            }),
        }));
    };

    const handleNavigate = () => {
        const selectedSurveyNames = surveyCard.
            data.filter((item) => item.type === 0)
            .map((item) => item.name);
        const mapIndexOfSelected = surveyCard.data.filter((item) => item.type === 0).map((item) => item.id);
        dispatch(saveSurveysList(selectedSurveyNames, surveyCard.uid, mapIndexOfSelected))
    };

    return (
        <PageProvider>
            <Title>ผลิตภัณฑ์/หัวข้อที่คุณสนใจ</Title>
            <div style={{ margin: '16px 0' }} >
                <Description>
                    เพื่อให้คุณได้รับข้อมูลที่ตรงใจมากที่สุด กรุณาเลือกผลิตภัณฑ์หรือหัวข้อที่คุณสนใจ (เลือกได้มากกว่า 1 ข้อ)
                </Description>
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '16px',
                justifyItems: 'center',
                overflow: 'auto',
            }}>
                {surveyCard.data.map((item) => (
                    <CheckButton
                        key={item.id}
                        text={item.name}
                        onClick={() => handleCheckBoxChange(item.id)}
                        type={item.type === 0 ? 0 : 2}
                    />))}
            </div>
            <ButtomSpan>
                <Button text={'เสร็จสิ้น'} onClick={handleNavigate} type={clickable > 0 ? 0 : 1} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default SurveyPage;
