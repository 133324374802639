import React, { CSSProperties, useEffect, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';

interface ConsentDetailProps {
    action?: string;
    entity: string | null;
    channel: string | null;
    updatedAt: string | null;
    signedVersion: string | null;
    consentReference: string | null;
    onClose: () => void;
}

interface ConsentDetailItem {
    title: string;
    detail: string | null;
}

const ConsentDetail: React.FC<ConsentDetailProps> = ({ action, entity, channel, updatedAt, signedVersion, consentReference, onClose }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const [consentDetail, setConsentDetail] = useState<ConsentDetailItem[]>([]);
    const [notValidate,setNotValidate] = useState(false);

    const handleMapAction = (action: string): string => {
        switch (action) {
            case 'N':
                return 'ไม่เคยให้ความยินยอม';
            case 'W':
                return 'ดำเนินการเพิกถอนความยินยอมแล้ว';
            case 'Y':
                return 'ดำเนินการให้ความยินยอมแล้ว';
            case 'P':
                return 'ไม่ให้ความยินยอม';
            default:
                setNotValidate(true);
                return 'ไม่ให้ความยินยอม';
        }
    };  

    const formatThaiDate = (dateString: string): string => {
        const months = [
            'ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย',
            'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'
        ];
        
        const date = new Date(dateString);
        
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear() + 543;
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    }

    useEffect(() => {
        setConsentDetail([
            { title: 'รายละเอียดความยินยอม', detail: action ? handleMapAction(action) : 'ไม่ให้ความยินยอม'},
            { title: 'บริษัทที่ให้ความยินยอม', detail: entity },
            { title: 'ช่องทางที่ให้ความยินยอม', detail: channel },
            { title: 'วันที่/เวลา ให้ความยินยอม', detail: updatedAt? formatThaiDate(updatedAt) : "" },
            { title: 'เวอร์ชั่น', detail: signedVersion },
            { title: 'เลขที่อ้างอิงความยินยอม', detail: consentReference },
        ])
    },[])

    const containerStyle: CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#FFFFFF', 
        zIndex: 1000,
        overflowY: 'auto',
    };

    const titleStyle: CSSProperties = {
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: 'Krungsri_Bold',
        textAlign: 'center',
        marginTop: '16px',
        marginBottom: '24px',
    };

    const description: CSSProperties = {
        fontSize: '14px',
        lineHeight: '22px',
        fontFamily: 'Krungsri_Light',
    };

    const subTitle: CSSProperties = {
        fontSize: '14px',
        lineHeight: '21px',
        fontFamily: 'Krungsri_Bold',
        wordWrap: 'break-word'
    };

    return (
        <div style={containerStyle}>
            <PageProvider>
                <img
                    onClick={onClose}
                    alt="BACK BUTTON"
                    src="/svg/back.svg"
                    style={{
                        height: 24,
                        width: 24,
                        cursor: 'pointer',
                        marginBottom: '12px'
                    }}
                />

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <img
                        alt="IMAGE"
                        src="/svg/bindingConsent.svg"
                        style={{
                            height: 160,
                            width: 160,
                        }}
                    />
                </div>

                <div style={titleStyle}>
                    รายละเอียดความยินยอม
                </div>
                
                {
                    action === 'N' ? 
                    <div style={{ marginTop: "24px", display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                        <div style={{ ...description, width: '163px' }}>
                            รายละเอียดความยินยอม
                        </div>
                        <div style={{ ...subTitle, width: '163px' }}>
                            ไม่เคยให้ความยินยอม
                        </div>
                    </div> :

                    consentDetail.map((item: ConsentDetailItem, index: number) => (
                        !(item.title === 'เลขที่อ้างอิงความยินยอม' && notValidate) && (
                        <div key={index} style={{ marginTop: "24px", display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                            <div style={{ ...description, width: '163px' }}>
                                {item.title}
                            </div>
                            <div style={{ ...subTitle, width: '163px' }}>
                                {item.detail}
                            </div>
                        </div>
                        )
                    ))
                }
            </PageProvider>
        </div>
    );
};

export default ConsentDetail;
