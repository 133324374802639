import { AxiosError } from 'axios';

interface Metadata {
    profileStatus: string;
    cooldownFinishedAt: string;
}

interface ErrorResponse {
    success: boolean;
    code: string;
    message: string;
    detailedErrors: string[] | null;
    metadata: Metadata;
}

interface WrappedErrorResponse {
    error: {
        code: string;
        message: string;
        detailedErrors: string[] | null;
        metadata?: Metadata;
    };
}

export const handleApiError = (error: AxiosError): ErrorResponse => {
    if (error.response && error.response.data) {
        const data = error.response.data as Partial<WrappedErrorResponse>; // Fix: Access the wrapped error

        return {
            success: false,
            code: data.error?.code || 'UNKNOWN',
            message: data.error?.message || 'An unexpected error occurred.',
            detailedErrors: data.error?.detailedErrors || null,
            metadata: {
                profileStatus: data.error?.metadata?.profileStatus || 'UNKNOWN',
                cooldownFinishedAt: data.error?.metadata?.cooldownFinishedAt || '',
            },
        };
    } else {
        return {
            success: false,
            code: 'UNKNOWN',
            message: error.message || 'Error in sending request',
            detailedErrors: null,
            metadata: {
                profileStatus: 'UNKNOWN',
                cooldownFinishedAt: '',
            },
        };
    }
};