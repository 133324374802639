import React from 'react';

const parseStyle = (style: string): React.CSSProperties => {
  const styles: Record<string, string> = {};
  const styleEntries = style.split(';').filter(Boolean);

  styleEntries.forEach((entry) => {
    const [key, value] = entry.split(':').map((str) => str.trim());
    if (key && value) {
      const camelCasedKey = key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());

      styles[camelCasedKey] = value;
    }
  });

  return styles as React.CSSProperties;
};

const sanitizeHtml = (html: string): Document => {
  const allowedTags = ['p', 'span', 'strong', 'b', 'i', 'u'];
  const allowedAttributes = ['style'];

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const cleanNode = (node: HTMLElement) => {
    Array.from(node.getElementsByTagName('*')).forEach((el) => {
      if (!allowedTags.includes(el.tagName.toLowerCase())) {
        el.remove();
        return;
      }

      Array.from(el.attributes).forEach((attr) => {
        if (!allowedAttributes.includes(attr.name)) {
          el.removeAttribute(attr.name);
        }
      });
    });
  };

  cleanNode(doc.body);
  return doc;
};

const renderHtmlToJsx = (node: ChildNode): React.ReactNode => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as HTMLElement;

    const tag = element.tagName.toLowerCase();
    const props: Record<string, string | React.CSSProperties> = {};

    Array.from(element.attributes).forEach((attr) => {
      if (attr.name === 'style') {
        props['style'] = parseStyle(attr.value);
      } else {
        props[attr.name] = attr.value;
      }
    });

    const children = Array.from(element.childNodes).map(renderHtmlToJsx);

    return React.createElement(tag, props, ...children);
  }

  return null;
};

const htmlToJsx = (html: string): React.ReactNode[] => {
  const sanitizedDoc = sanitizeHtml(html);
  return Array.from(sanitizedDoc.body.childNodes).map(renderHtmlToJsx);
};

export default htmlToJsx;
